import React from 'react'
import { navigate } from 'gatsby'

import {
  PostContainer,
  PostImage,
  PostTitle,
  PostDate,
  PostDescription,
} from './Post.styles'

interface Props {
  bg: string
  title: string
  date: string
  description: string
  link: string
}

const Post = ({ bg, title, date, description, link }: Props) => {
  const getImage = (path: string) => {
    const imagePath = require(`../../../content/blog/${path}`)

    return imagePath
  }

  return (
    <PostContainer onClick={() => navigate(`${link}`)}>
      <div>
        <PostImage bg={getImage(bg)} />
      </div>
      <div>
        <PostTitle>{title}</PostTitle>
        <PostDate>{date}</PostDate>
        <PostDescription>{description}</PostDescription>
      </div>
    </PostContainer>
  )
}

export default Post
