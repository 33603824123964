import React, { useState, useEffect } from 'react'
import * as dayjs from 'dayjs'
import Fade from 'react-reveal/Fade'

import Layout from 'layouts/index'
import TitleBox from 'components/TitleBox'
import Post from 'containers/Post'
import CenterContainer from 'containers/CenterContainer'

import dog from 'images/dog_run.jpg'

const DicasDerosso = ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <TitleBox title='Dicas Derosso' bg={dog} />
      <Fade>
        <CenterContainer>
          {data.allMarkdownRemark.edges &&
            data.allMarkdownRemark.edges.map((post: any) => {
              if (post.node.frontmatter.slug === 'test-post') return <></>
              return (
                <Post
                  key={post.id}
                  bg={post.node.frontmatter.image.relativePath}
                  title={post.node.frontmatter.title}
                  date={dayjs(post.node.frontmatter.date).format('DD/MM/YYYY')}
                  description={post.node.frontmatter.description}
                  link={post.node.frontmatter.slug}
                />
              )
            })}
        </CenterContainer>
      </Fade>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogIndexQuery {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            description
            slug
            image {
              relativePath
              absolutePath
            }
          }
        }
      }
    }
  }
`

export default DicasDerosso
