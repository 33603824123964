import styled from 'styled-components'

export const PostContainer = styled.div`
  width: 80%;
  display: flex;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;

  @media (min-width: 765px) {
    padding: 25px 50px;
  }
`

interface PostImageProps {
  bg: string
}

export const PostImage = styled.div<PostImageProps>`
  background-image: ${props => `url(${props.bg})`};
  background-size: cover;
  background-position: center center;
  width: 120px;
  height: 120px;
  margin-right: 20px;
`

export const PostTitle = styled.h2`
  font-family: 'Raleway', sans-serif;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 5px;
`

export const PostDate = styled.div`
  font-family: 'Raleway', sans-serif;
  color: ${props => props.theme.colors.black};
  margin-bottom: 5px;
`

export const PostDescription = styled.div`
  font-family: 'Roboto Slab', serif;
  color: ${props => props.theme.colors.black};
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
